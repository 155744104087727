import { apiAuthorizedRequest } from '@/services/ApiService';

/**
 * Method to get info about payment
 *
 * @param authToken
 * @param orderId
 * @returns {Promise<*>}
 */
export const getPaymentInfo = ({ authId, microTransactionId, orderId }) => {
  return apiAuthorizedRequest(
    'GET',
    `/api/v1/auths/${authId}/micro_transactions/${microTransactionId}/payment/${orderId}`,
  );
};
