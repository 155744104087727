<template>
  <v-menu v-model="show" offset-y z-index="1000000000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" v-bind="attrs" v-on="on">
        <v-icon>mdi-fountain-pen-tip</v-icon>
      </v-btn>
    </template>

    <v-card width="22rem">
      <v-list>
        <v-list-item
          v-for="identity in signIdentities"
          :key="identity.id"
          class="py-1 mx-3 px-0 document-person-status__row"
        >
          <v-row align="center" justify="start">
            <v-col class="col-2">
              <SAvatar
                :size="40"
                :firstname="identity.firstname"
                :lastname="identity.lastname"
                :position="identity.variable_position"
                :profileImage="identity.avatar_url"
                :background-color="identity.color"
              />
            </v-col>

            <v-col class="col-7 pl-0">
              <v-row align="center" justify="start" no-gutters>
                <v-col class="col-12 pb-0">
                  <div class="font-weight-medium">
                    {{ getIdentityFullName(identity) }}
                  </div>
                </v-col>

                <v-col class="col-12 pb-0">
                  <div class="font-weight-normal grey--text document-person-status__row--item">
                    <span
                      :style="identity.is_signed || identity.is_approved ? 'color: #6cecb0' : ''"
                    >
                      {{ getContractRoleStatus(identity) }}
                    </span>
                    {{ getDate(identity) }}
                  </div>
                </v-col>

                <v-col v-if="isProposer" class="col-12 pb-0">
                  <div class="font-weight-normal grey--text document-person-status__row--item">
                    {{ identity.email }}
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="col-3 pl-0 pb-0">
              <v-row align="center" justify="end" no-gutters>
                <v-col class="col-6 px-2">
                  <v-badge color="" overlap>
                    <template v-slot:badge>
                      <v-icon
                        v-if="identity.is_approved || identity.is_signed"
                        color="#6cecb0"
                        style="border: 2px solid #6cecb0; border-radius: 50%"
                      >
                        mdi-check-bold
                      </v-icon>

                      <v-icon
                        v-else-if="identity.rejected_at"
                        color="#DA2029"
                        style="border: 2px solid #da2029; border-radius: 50%"
                      >
                        mdi-close-thick
                      </v-icon>
                    </template>

                    <v-icon v-if="identity.contract_role === 'approve'" color="#B9C6D9">
                      mdi-file-check-outline
                    </v-icon>
                    <v-icon
                      v-else-if="
                        identity.contract_role === 'sign' ||
                        identity.contract_role === 'stamp' ||
                        identity.contract_role === 'sign_certificate' ||
                        identity.contract_role === 'sign_bank_id_sign'
                      "
                      color="#B9C6D9"
                      style="zoom: 1.2"
                    >
                      mdi-fountain-pen-tip
                    </v-icon>
                  </v-badge>
                </v-col>

                <v-col class="col-6 px-2">
                  <v-badge
                    color=""
                    :value="identity.is_viewed || identity.is_contract_viewed"
                    overlap
                  >
                    <template v-slot:badge>
                      <v-icon color="#6cecb0" style="border: 2px solid #6cecb0; border-radius: 50%">
                        mdi-check-bold
                      </v-icon>
                    </template>

                    <v-icon color="#B9C6D9">mdi-eye-outline</v-icon>
                  </v-badge>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';
import SAvatar from '@/common/components/SAvatar';

const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export default {
  name: 'DocumentPersonsStatus',
  components: { SAvatar },
  props: {
    signIdentities: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isProposer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      signIdentityStates: {
        approve: this.$t('contract.signs.role_approve'),
        approved: this.$t('general.approved'),
        sign: this.$t('docs.pending.generic'),
        signed: this.$t('signing.signed'),
      },
    };
  },
  computed: {},
  methods: {
    getContractRoleStatus(identity) {
      if ('approve' === identity.contract_role) {
        return this.signIdentityStates[identity.is_approved ? 'approved' : 'approve'];
      }

      if (
        'sign' === identity.contract_role ||
        'stamp' === identity.contract_role ||
        'sign_certificate' === identity.contract_role ||
        'sign_bank_id_sign' === identity.contract_role
      ) {
        return this.signIdentityStates[identity.is_signed ? 'signed' : 'sign'];
      }
    },
    getDate(identity) {
      if ('approve' === identity.contract_role && identity.approved_at) {
        return `${getFormattedDateByLocale({
          date: identity.approved_at,
          options: dateOptions,
        })}`;
      }

      if (
        ('sign' === identity.contract_role ||
          'stamp' === identity.contract_role ||
          'sign_certificate' === identity.contract_role ||
          'sign_bank_id_sign' === identity.contract_role) &&
        identity.signature_date
      ) {
        return `${getFormattedDateByLocale({
          date: identity.signature_date,
          options: dateOptions,
        })}`;
      }

      return '';
    },
    getIdentityFullName(identity) {
      if (identity?.variable_position) {
        return `${identity.variable_position}`;
      }

      return `${identity.firstname} ${identity.lastname}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.document-person-status {
  &__row {
    border-bottom: 1px solid #d4dfef;

    &--item::v-deep {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.9rem;
    }
  }

  &__row:last-child {
    border-bottom: unset;
  }
}
</style>
