<template>
  <v-menu transition="slide-y-transition" bottom offset-y min-width="15rem" max-width="16rem">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="pl-3 pr-1" outlined x-large v-bind="attrs" v-on="on">
        {{ $t('general.more') }}
        <v-icon class="pa-0 ma-0 pl-1"> mdi-dots-vertical </v-icon>
      </v-btn>
    </template>

    <div class="contract-more-menu__active">
      <slot />
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'MoreMenu',
};
</script>

<style lang="scss">
.v-menu__content {
  z-index: 1000 !important;
}

.contract-more-menu__active {
  background: white;
  border-radius: 8px;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.65);
  max-width: 240px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.contract-more-menu__actions {
  div {
    display: flex;
    flex-shrink: 0;
    margin: 15px 10px !important;

    & > a {
      flex: 90%;
      text-align: left;
      padding-left: 10px;
      color: #5f0bb9;
      font-weight: initial;
      font-size: 15px;
    }

    & > svg {
      position: relative;
      top: -3px;
    }
  }

  &-reject {
    border-top: 1px solid #e0e3e8;
    padding-top: 15px;
  }

  &-delete {
    border-top: 1px solid #e0e3e8;
    padding-top: 15px;

    a {
      color: #7d8086 !important;
    }
  }
}
</style>
