<template>
  <v-menu v-model="open" transition="slide-y-transition" bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="pl-3 pr-1"
        :block="$vuetify.breakpoint.mobile"
        min-width="10rem"
        outlined
        x-large
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('general.send') }}
        <v-icon v-if="open" class="pl-2"> mdi-chevron-up </v-icon>
        <v-icon v-else class="pl-2"> mdi-chevron-down </v-icon>
      </v-btn>
    </template>

    <div class="contract-actions-menu__active">
      <slot />
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'ActionsMenu',
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style lang="scss">
.contract-actions-menu__active {
  background: white;
  border-radius: 6px;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.65);
  width: 240px;
  padding: 15px;
  z-index: 550;
  display: flex;
  flex-direction: column;

  &--top-links {
    padding: 5px 0;
    margin-bottom: 5px;
  }

  &--link {
    margin: 10px 0;
    a {
      font-size: 14px;
      text-align: left;
      color: #885cb8 !important;
    }
  }

  &--link:first-child {
    margin-top: 0;
  }

  &--link:last-child {
    margin-bottom: 0;
  }

  &--resend-links {
    border-top: 1px solid #d8d8d8;
    padding: 15px 0;

    * {
      color: #000000;
      font-size: 14px;
      font-weight: initial;
    }
  }
}
</style>
