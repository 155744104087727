<template>
  <v-col cols="12">
    <v-row align="start">
      <v-col class="col-12 col-md-auto py-1 pl-0 pr-5 text-left font-weight-medium">
        {{ addressType }}:
      </v-col>

      <v-col class="col-12 col-md-auto py-1 pl-0 text-left">
        <v-row align="center" justify="start">
          <v-col cols="12" class="pb-0">
            {{ `${street} ${streetNumber}` }}
          </v-col>
          <v-col cols="12">
            {{ `${zipcode}, ${city}, ${country}` }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'BankIdContentCardAddressItem',
  props: {
    bankIdAddress: {
      type: Object,
      required: true,
    },
    idAuth: {
      type: [String, Number],
      required: true,
    },
    idAuthBankId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    addressType() {
      return this.$t(`address.type.${this.bankIdAddress.type}`);
    },
    street() {
      return this.bankIdAddress?.street || this.$t('general.not_specified');
    },
    streetNumber() {
      const buildingApartment = this.bankIdAddress?.building_apartment;
      const streetNumber = this.bankIdAddress?.street_number;

      return streetNumber ? `${buildingApartment}/${streetNumber}` : buildingApartment || '';
    },
    city() {
      return this.bankIdAddress?.city || this.$t('general.not_specified');
    },
    zipcode() {
      return this.bankIdAddress?.zipcode || this.$t('general.not_specified');
    },
    country() {
      return this.bankIdAddress?.country || this.$t('general.not_specified');
    },
  },
};
</script>

<style scoped></style>
