<template>
  <div
    class="pl-2 pr-4 pr-md-2 py-5 contract-document-tabs__tab"
    :class="{
      'contract-document-tabs__tab-active': canShowHighlighting,
    }"
  >
    <v-row class="pb-1" align="center" justify="start" no-gutters>
      <v-col cols="auto" :offset="isMobileDevice ? 1 : 0" class="pl-2">
        <ContractDocumentState :contract="contract" :active="isTabActive" />
      </v-col>

      <v-col cols="auto" class="pl-1">
        <ContractDocumentTabInfo
          :contract-id="contract.id"
          :current-sign-identity="currentSignIdentityForTabInfo"
          :sign-identities="contract.sign_identities"
        />
      </v-col>

      <v-col></v-col>

      <v-col class="text-right" order="auto">
        <v-menu
          transition="slide-y-transition"
          bottom
          offset-y
          min-width="15rem"
          max-width="16rem"
          style="z-index: 1001 !important"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0" max-width="17rem">
            <v-list-item>
              <v-row justify="space-between" no-gutters>
                <v-col>
                  <span class="contract-document-tab__menu-created">
                    {{ $t('general.created_at') }}:
                  </span>
                </v-col>

                <v-col class="text-right">
                  <span class="contract-document-tab__menu-created">
                    {{ $d(contract.created) }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item
              v-if="canDownloadDocument"
              link
              @click.prevent="downloadContractDocument(contract.id)"
            >
              <v-list-item-icon class="mr-3">
                <v-icon color="#AEBACE">mdi-download</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-light text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('contract.download_pdf') }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="canDownloadComments"
              link
              @click.prevent="downloadContractDocumentComments"
            >
              <v-list-item-icon class="mr-3">
                <v-icon color="#AEBACE">mdi-download</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-light text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('document.download_with_comments') }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click.prevent="downloadContractDocumentControlList(contract.id)">
              <v-list-item-icon class="mr-3">
                <IcoControlList />
              </v-list-item-icon>
              <v-list-item-content
                class="font-weight-light text-decoration-underline text-left py-2"
                style="color: #666b71 !important"
              >
                {{ $t('contract.show_revisions') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="mt-0 pr-3 pr-md-0" align="start" justify="start" no-gutters>
      <v-col v-if="isMobileDevice" cols="1">
        <v-btn icon class="pt-0 mt-0" @click="changeContract(contract)">
          <v-icon v-if="isTabActive" class="pt-0 mt-0" color="primary" large>
            mdi-chevron-up
          </v-icon>
          <v-icon v-else color="primary" large> mdi-chevron-down </v-icon>
        </v-btn>
      </v-col>

      <v-col cols="11" class="text-left pl-2">
        <a
          href="#"
          class="contract-document-tabs__document-link"
          @click.prevent="changeContract(contract)"
        >
          <span class="text-decoration-underline" style="word-wrap: break-word; overflow: hidden">
            {{ contract.title }}
          </span>
        </a>
      </v-col>
    </v-row>

    <div v-if="canExpand" class="contract-document-tab__content">
      <slot></slot>

      <div
        class="pa-10 pb-3"
        style="position: fixed; bottom: 1rem; left: 0; z-index: 10; width: 100%"
      >
        <v-btn
          v-if="showSignButton"
          color="primary"
          elevation="24"
          block
          x-large
          @click="$emit('sign-contract')"
        >
          <v-row justify="center" no-gutters>
            <v-col cols="auto" class="pr-3">
              <IcoCheck />
            </v-col>

            <v-col cols="auto">
              {{ $t('contract.sign') }}
            </v-col>
          </v-row>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ContractHelper } from '@/helpers/ContractHelper';
import { ContractService } from '@/services/ContractService';
import { DocumentWithComments } from '@contract/services/CommentsService';
import FileSaver from 'file-saver';
import ContractDocumentState from '@contract/components/ContractDocumentState';
import IcoControlList from '@/components/svg/IcoControlList';
import IcoCheck from '@/components/svg/IcoCheck';
import ContractDocumentTabInfo from '@contract/components/ContractDocumentTabInfo';
import { getContractCheckList } from '@contract/services/contractService';
import axios from 'axios';
import { goToLinkWithTarget } from '@/common/reusable/routing';

export default {
  name: 'ContractDocumentTab',
  components: {
    ContractDocumentTabInfo,
    IcoCheck,
    ContractDocumentState,
    IcoControlList,
  },
  props: {
    mainContractId: {
      required: true,
    },
    contract: {
      required: true,
    },
    showSignButton: {
      type: Boolean,
      default: false,
    },
    workspace_id: {
      required: true,
    },
    isAmlActive: {
      type: Boolean,
      default: false,
    },
    currentSignIdentity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      canDownloadComments: false,
      collapsed: false,
      primaryColor: 'primary',
    };
  },
  computed: {
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    isTabActive() {
      return this.selectedContractId === this.contract.id && !this.isAmlActive && !this.collapsed;
    },
    canDownloadDocument() {
      if ('undefined' === typeof this.contract?.current_user?.can_download_pdf) {
        return true;
      }

      return this.contract?.current_user?.can_download_pdf;
    },
    canShowHighlighting() {
      return !this.isMobileDevice && this.isTabActive;
    },
    canExpand() {
      return this.isMobileDevice && this.isTabActive && !this.collapsed;
    },
    selectedContractId() {
      return parseInt(this.$route.params.contract_id);
    },
    currentSignIdentityForTabInfo() {
      return (
        this.contract.sign_identities.find((identity) => {
          return identity.email === this.currentSignIdentity?.email;
        }) || null
      );
    },
  },
  watch: {
    collapsed: {
      handler: function (value) {
        this.$emit('hide-signatures', value);
      },
    },
    'contract.comments': {
      deep: true,
      handler: function (value) {
        this.canDownloadComments = !!value?.length;
      },
    },
  },
  async mounted() {
    if ('comments' === this.contract?.comments_usage) {
      await ContractService.getComments(this.contract?.id, 'comments').then((resp) => {
        this.contract.comments = resp || [];
        this.canDownloadComments = 'doc' === this.contract?.type && !!resp?.length;
      });
    }
  },
  methods: {
    ...mapActions({
      setPagesLoading: 'pdfDocument/setPagesLoading',
    }),
    arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = [].slice.call(new Uint8Array(buffer));
      // eslint-disable-next-line no-return-assign
      bytes.forEach((b) => (binary += String.fromCharCode(b)));

      return window.btoa(binary);
    },
    getDownloadedFile(context, prefix = '') {
      context.arrayBuffer().then((buffer) => {
        const base64Flag = 'data:application/pdf;base64,';
        const imageStr = this.arrayBufferToBase64(buffer);
        FileSaver.saveAs(base64Flag + imageStr, `${prefix}${this.contract.title}.pdf`);
        this.loadingCheckList = false;
      });
    },
    downloadContractDocument(contractId) {
      ContractService.getPDF(contractId).then((response) => this.getDownloadedFile(response));
    },
    downloadContractDocumentComments() {
      const documentWithComments = new DocumentWithComments({
        contractId: this.contract?.id,
        title: this.contract?.title,
        signIdentities: this.contract?.sign_identities,
        locale: this.contract?.locale,
      });

      documentWithComments.generate();
    },
    downloadContractDocumentControlList(contractId) {
      if (this.contract.state !== 'completed') {
        return goToLinkWithTarget(
          this.$router.resolve({
            name: 'contractDocumentRevisionList',
            params: {
              contractId: this.$route.params.contract_id,
            },
          }).href,
        );
      }

      this.$notification.warning(this.$t('contract.checklist.preparing'));

      getContractCheckList({
        contractId,
        attempt: 5,
        timeout: 2500,
        next: (resp) => {
          axios({ url: resp.url, method: 'GET', responseType: 'blob' }).then((response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', `KL_${this.contract.title}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        },
        catchError: () => {
          this.$notification.error(this.$t('contract.checklist.error'));
        },
      });
    },
    isUnsigned(contract) {
      if (ContractHelper.isApprovalRequiredForCurrentUser(contract)) {
        return ContractHelper.isUnsignedOrUnapproved(contract) ? 'pending.approve' : 'done.approve';
      }

      if (
        ContractHelper.isSignatureRequiredForCurrentUser(contract) &&
        !ContractHelper.isUnsigned(contract)
      ) {
        return 'done.sign';
      }

      return contract.state;
    },
    changeContract(contract) {
      if (parseInt(contract.id) === this.selectedContractId && this.$vuetify.breakpoint.mobile) {
        if (!this.collapsed) {
          this.setPagesLoading();
        }

        this.collapsed = this.$vuetify.breakpoint.mobile ? !this.collapsed : false;

        return;
      }

      this.$emit('change-contract', contract);
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-document-tabs {
  &__tab {
    border-bottom: 1px solid #d8d8d8;
    background-color: #ffffff;
    position: relative;

    &-active {
      border-left: 3px solid #7240ab;
    }

    &-active::after {
      content: '';
      position: absolute;
      top: 25%;
      right: -14px;
      z-index: 1;
      border-bottom: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      background-color: #ffffff;
      transform: rotate(-45deg);
      height: 25px;
      width: 25px;
    }

    &__content {
      border-top: 1px solid #d8d8d8;
    }
  }

  &__document-link {
    color: var(--v-primary-base) !important;
    word-wrap: break-word;
    overflow: hidden;
  }
}

.contract-document-tab__menu-created {
  font-size: 14px;
  color: #7d8593;
}

.contract-document-tab__document-link {
  color: var(--v-primary-base) !important;
}

.contract-document-tab__menu {
  &--link {
    display: flex;
    justify-content: space-between;
    max-width: 220px;

    margin: 15px 10px;

    & > a {
      flex: 90%;
      padding-left: 10px;
      font-weight: initial;
      font-size: 15px !important;
      color: #666b71 !important;
      text-align: left !important;
      line-height: 20px !important;
      word-wrap: break-word;
    }

    & > svg {
      position: relative;
      top: -1px;
    }

    &.separately {
      border-top: 1px solid #e0e3e8;
      padding-top: 15px !important;
    }
  }
}
</style>
