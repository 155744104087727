<template>
  <div
    class="px-2 py-5 contract-document-tabs__tab"
    :class="{
      'contract-document-tabs__tab-active': canShowHighlighting,
    }"
  >
    <v-row class="mt-0" align="start" justify="start" no-gutters>
      <v-col v-if="isMobileDevice" cols="1">
        <v-btn icon @click="turnOnAmlContent">
          <v-icon v-if="showAmlContent" color="primary" large> mdi-chevron-up </v-icon>
          <v-icon v-else color="primary" large> mdi-chevron-down </v-icon>
        </v-btn>
      </v-col>

      <v-col cols="11" class="text-left pl-2">
        <v-btn color="primary" class="pl-1" text block @click="turnOnAmlContent">
          <span class="text-decoration-underline">
            {{ title }}
          </span>
          <span class="mr-auto"></span>
        </v-btn>
      </v-col>
    </v-row>

    <v-slide-y-transition>
      <div v-if="canExpand">
        <slot></slot>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {
  name: 'AmlActivator',
  props: {
    value: Boolean,
    amlCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showAmlContent: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    canShowHighlighting() {
      return !this.isMobileDevice && this.showAmlContent;
    },
    canExpand() {
      return this.isMobileDevice && this.showAmlContent;
    },
    title() {
      return `${this.$t('verification')}${this.amlCount !== null ? ` (${this.amlCount})` : ''}`;
    },
  },
  methods: {
    turnOnAmlContent() {
      this.showAmlContent = this.isMobileDevice
        ? (this.showAmlContent = !this.showAmlContent)
        : true;
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-document-tabs {
  &__tab {
    border-bottom: 1px solid #d8d8d8;
    background-color: #ffffff;
    position: relative;

    &-active {
      border-left: 3px solid #7240ab;
    }

    &-active::after {
      content: '';
      position: absolute;
      top: 25%;
      right: -14px;
      z-index: 1;
      border-bottom: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      background-color: #ffffff;
      transform: rotate(-45deg);
      height: 25px;
      width: 25px;
    }
  }
}
</style>
